import React, { useContext, useRef } from 'react'
import { formateDate, formateValidatedDate } from '../../../../../services/dateHandless'
import { formContext } from '../../../../../context/formContext'
const RenderTH = ({ item, isNew=false, handleDelete=null }) => {
	const button = useRef(null)
	const { handleForm } = useContext(formContext)
	const handleEdit = () => {
		handleForm({
			...item,
			acob_date_prev_fim: formateValidatedDate(item.acob_date_prev_fim),
			acob_date_prev: formateValidatedDate(item.acob_date_prev),
		})
		if (button?.current?.click) {
			button.current.click()
		}
	}
	return (
		<>
			<tr>
				{!isNew&&<th scope="row">{item.acob_pl_ob_cod}</th >}
				<td>{formateDate(item.acob_date_prev)}</td>
				<td>{formateDate(item.acob_date_prev_fim)}</td>
				<td>{parseFloat(item.acob_perc_prev).toFixed(2)}</td>
				<td>
					{!isNew&&<button className="btn btn-danger btn-sm mr-2" onClick={handleEdit}>
						<i className="fa fa-edit"></i>
					</button>}
					<button type="button" className="btn btn-secondary btn-sm" onClick={()=>handleDelete(item.id)}>
						<i className="fa fa-trash"></i>
					</button>
				</td>
			</tr>
			<button className="d-none" data-toggle="modal" ref={button} data-target="#modalMonitoring"></button>
		</>
	)
}
export default RenderTH
import React, { useContext, useEffect, useState } from 'react'
import { changeUnit, enableDoubleClickToCopy, formatCurrency, formatPercentage } from '../../../../services/stringHandless'
import FormServiceConstructionPlan from '../../../form/serviceConstructionPlan'
import { formContext } from '../../../../context/formContext'
import { Link, useLocation } from 'react-router-dom'
import { authContext } from '../../../../context/authContext'
import ServiceAdvancement from '../../../modal/serviceAdvancement'
import { formateDate } from '../../../../services/dateHandless'
import FormApprovedRecusedProgress from '../../../form/approvedRecusedProgressConstructionPlan'
import { closeModal, namesScreens, openModal } from '../../../../constants'
import { requestContext } from '../../../../context/requestContext'
import { handleSuccess } from '../../../../services/isValid'
import { storageCache } from '../../../../services/cacheManager'
const RenderTH = ({ item, enabledEdit }) => {
	const { handleForm, state } = useContext(formContext)
	const { setInfoRequest } = useContext(requestContext)
	const { serv_desc, serv_sub_cat_desc } = state
	const { user } = useContext(authContext)
	const [openModalAdvance, setOpenModalAdvance] = useState(closeModal)
	const [openModalEdit, setOpenModalEdit] = useState(closeModal)
	const [openModalFisc, setOpenModalFisc] = useState(closeModal)
	const location = useLocation()
	const [disabled, setDisabled] = useState(false)
	useEffect(() => {
		const controllDisabledInputs = () => {
			const { pathname } = location
			if (pathname === namesScreens.REPORT_CONSTRUCTION_PLAN) {
				return setDisabled(true)
			}
			return setDisabled(false)
		}
		controllDisabledInputs()
	}, [location])
	const handleEdit = () => {
		handleForm({
			...item,
			ane_pl_ob_pvs_cod: item.prog_serv_serv_cod,
		})
		if (!enabledEdit) {
			return
		}
		if (user?.set_nivel === 0) {
			return setOpenModalEdit(openModal)
		}
		setOpenModalAdvance(openModal)
	}
	const handleProgress = () => {
		handleForm({
			...item,
			ane_pl_ob_pvs_cod: item.prog_serv_serv_cod,
		})
		setOpenModalAdvance(openModal)
	}
	const handleClear = () => {
		handleForm({
			serv_sub_cat_subitem_der: '',
			prog_serv_qtd_acul: '',
			prog_serv_percen_progress: '',
			ob_serv_med_desc: '',
			prog_serv_percen_acul: '',
		})
		setOpenModalAdvance(closeModal)
		setOpenModalEdit(closeModal)
		setOpenModalFisc(closeModal)
	}
	const generateAddFiscObs = ({
		fisc_adicional_parametros,
		fisc_obs
	}) => {
		try {
			const parseJson = JSON.parse(fisc_adicional_parametros)
			if (!parseJson) {
				throw new Error('Não foi possível converter fisc_adicional_parametros')
			}
			const {
				progresso_obra_servico: {
					prog_serv_percen_acul,
				}
			} = parseJson
			const add_fisc_obs = `${fisc_obs}. E também alterou o acumulado do serviço de ${parseFloat(prog_serv_percen_acul).toFixed(2)} para ${parseFloat(item.prog_serv_percen_acul).toFixed(2)}.`
			return add_fisc_obs
		} catch (error) {
			console.log(error)
			return null
		}
	}
	const handleFisc = () => {
		if (user?.set_nivel !== 0 || !item?.pvs_fisc) {
			return
		}
		const { fisc_adicional_parametros, fisc_obs } = item
		const add_fisc_obs = generateAddFiscObs({
			fisc_adicional_parametros, fisc_obs
		})
		if (add_fisc_obs) {
			handleForm({
				...item,
				fisc_obs: add_fisc_obs,
				fisc_cod: item.pvs_fisc
			})
		} else {
			handleForm({
				...item,
				fisc_cod: item.pvs_fisc
			})
		}
		setOpenModalFisc(openModal)
	}
	const onDoubleClick = async (event) => {
		const result = await enableDoubleClickToCopy(event.target)
		if (result) {
			storageCache('lass_message')
			return setInfoRequest(handleSuccess(`Texto ${result} copiado para área de transferência`))
		}
	}
	return (
		<>
			<tr>
				<th onDoubleClick={onDoubleClick} scope="row">
					{item.serv_sub_cat_subitem_der}
				</th>
				{!disabled ? (
					<td onDoubleClick={onDoubleClick}>
						{item.st_desc ? (
							<span className="badge" onClick={handleFisc} style={{
								backgroundColor: item.st_cor_valor,
								cursor: 'pointer'
							}}>{item.st_desc}</span>
						) : ''}
					</td>
				) : ''}
				<td onDoubleClick={onDoubleClick}>
					{item.serv_sub_cat_desc}
				</td>
				<td onDoubleClick={onDoubleClick}>
					{formatCurrency(item.pvs_qtd, false)} {changeUnit(item?.ob_serv_med_desc)}
				</td>
				<td onDoubleClick={onDoubleClick}>
					<Link to={'#'} className="text-dark text-decoration-none" onClick={handleProgress}>
						{formatCurrency(item.prog_serv_qtd_acul, false)} {changeUnit(item?.ob_serv_med_desc)}
					</Link>
				</td>
				<td onDoubleClick={onDoubleClick}>
					<Link to={'#'} className="text-dark text-decoration-none" onClick={handleProgress}>
						{formatCurrency(item.prog_serv_qtd_acul_ant, false)} {changeUnit(item?.ob_serv_med_desc)}
					</Link>
				</td>
				<td onDoubleClick={onDoubleClick}>
					{formatPercentage(item.prog_serv_percen_progress)}
				</td>
				<td onDoubleClick={onDoubleClick}>
					{formatPercentage(item.prog_serv_percen_acul_ant)}
				</td>
				<td onDoubleClick={onDoubleClick}>
					<Link to={'#'} className="text-dark text-decoration-none" onClick={handleProgress}>
						{formatPercentage(item.prog_serv_percen_acul)}
					</Link>
				</td>
				{!disabled ? (
					<td onDoubleClick={onDoubleClick}>
						{enabledEdit ? (
							<>
								{user?.set_nivel === 0 ? (
									<button className="btn btn-danger btn-sm mr-2" onClick={handleEdit}>
										<i className="fa fa-edit"></i>
									</button>
								) : (
									<button className="btn btn-danger btn-sm mr-2" onClick={handleProgress}>
										<i className="fa fa-arrow-right"></i>
									</button>
								)}
							</>
						) :
							formateDate(item?.created_at)
						}
					</td>
				) : ''}
			</tr>
			<div className={openModalAdvance.className} id={`ServiceAdvancement${item.prog_serv_cod}`} style={openModalAdvance.style}
				tabIndex="-1" role="dialog" aria-labelledby={`ServiceAdvancementLabel${item.prog_serv_cod}`} aria-hidden="true">
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id={`ServiceAdvancementLabel${item.prog_serv_cod}`}>
								{serv_desc} | {serv_sub_cat_desc}
							</h5>
							<button type="button" onClick={handleClear} className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<ServiceAdvancement closeModal={handleClear} />
						</div>
					</div>
				</div>
			</div>
			<div className={openModalEdit.className} id={`FormServiceConstructionPlan${item.prog_serv_cod}`} style={openModalEdit.style} tabIndex="-1" role="dialog" aria-labelledby="FormServiceConstructionPlanLabel" aria-hidden="true">
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id={`FormServiceConstructionPlanLabel${item.prog_serv_cod}`}>
								{serv_desc} | {serv_sub_cat_desc}
							</h5>
							<button type="button" onClick={handleClear} className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<FormServiceConstructionPlan closeModal={handleClear} />
						</div>
					</div>
				</div>
			</div>
			<div className={openModalFisc.className} id={`FormApprovedRecusedProgress${item.prog_serv_cod}`} style={openModalFisc.style} tabIndex="-1" role="dialog" aria-labelledby="FormApprovedRecusedProgressLabel" aria-hidden="true">
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id={`FormApprovedRecusedProgressLabel${item.prog_serv_cod}`}>
								{serv_desc} | {serv_sub_cat_desc}
							</h5>
							<button type="button" onClick={handleClear} className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<FormApprovedRecusedProgress closeModal={handleClear} />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default RenderTH